/* website: 3838-kia-reddeer
 * created at 2022-09-12 11:30 by Rymma
 */

// Import all makes and organization styles files
@import "../templates/makes/kia.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.dealer__kiareddeer-733{
    .listing-new-tile .listing-new-tile-image{
      .new-car-image-wrapper{
        height: 270px;
        overflow: hidden;
      }
      img{
        width: 100%;
      }
    }
    .listing-new-tile .listing-new-tile-wrapper,
    .listing-new-tile .dealer-image{
      padding: 20% 3% 3%;
      height: auto;
    }

    .listing-tile .listing-tile-image .car-info{
      position: relative;
    }
    .listing-tile .listing-tile-image{
      display:flex;
      flex-direction: column-reverse;
    }
  }
}